exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buy-index-tsx": () => import("./../../../src/pages/buy/index.tsx" /* webpackChunkName: "component---src-pages-buy-index-tsx" */),
  "component---src-pages-company-about-us-tsx": () => import("./../../../src/pages/company/about-us.tsx" /* webpackChunkName: "component---src-pages-company-about-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-privacy-index-tsx": () => import("./../../../src/pages/legal/privacy/index.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-index-tsx" */),
  "component---src-pages-legal-terms-index-tsx": () => import("./../../../src/pages/legal/terms/index.tsx" /* webpackChunkName: "component---src-pages-legal-terms-index-tsx" */),
  "component---src-pages-otc-index-tsx": () => import("./../../../src/pages/otc/index.tsx" /* webpackChunkName: "component---src-pages-otc-index-tsx" */),
  "component---src-pages-resources-support-tsx": () => import("./../../../src/pages/resources/support.tsx" /* webpackChunkName: "component---src-pages-resources-support-tsx" */)
}

