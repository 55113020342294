import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { CacheProvider } from '@emotion/react';
import { createEmotionCache } from './src/styles/theme/emotion-cache';
import AOS from 'aos';

const cache = createEmotionCache();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return (
    <CacheProvider value={cache}>
      <InitAOS>{element}</InitAOS>
    </CacheProvider>
  );
};

const InitAOS = ({ children }) => {
  React.useEffect(() => {
    AOS.init({
      duration: 700,
      once: true,
    });
  }, []);

  return <>{children}</>;
};
